import React from "react"

import { graphql } from "gatsby"
import { useI18next } from 'gatsby-plugin-react-i18next';

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Projects from "../components/Projects"

const ProjectsPage = ({ data }) => {
  const { allStrapiProject: { nodes: projects } } = data;
  const { language } = useI18next();

  return (
    <Layout>
      <Seo title="Proyectos" />
      <main className="projects-page">
        <section className="projects">
          <Projects
            title="Todos mis proyectos"
            showlink={true}
            projects={projects}
            locale={language}
          />
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } } ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiProject {
      nodes {
        image {
          created_at
          url
          localFile {
            publicURL
            url
            childImageSharp {
              gatsbyImageData(
                layout: FIXED,
                blurredOptions: {},
                placeholder: BLURRED,
                height: 260,
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        github
        featured
        description
        slug
        title
        url
        stack {
          id
          title
        }
        id
        locale
      }
    }
  }
`;

export default ProjectsPage